import './index.css';
import QueueItem from './QueueItem';
import React, { useState, useEffect, useCallback, useRef } from "react";
import YouTube from "react-youtube";
import useQueue from "./hooks/useQueue";

import debounce from "lodash/debounce";
import { extractVideoId } from './utils';
import { useNavigate } from 'react-router-dom';

function App() {

  const { getQueue, dequeue, updateQueueItemTitle } = useQueue();

  const [queue, setQueue] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const playerRef = useRef(null);

  const debouncedCallback = useCallback(
    debounce(async (newQueue) => {
      const updatedQueue = await Promise.all(
        newQueue.map(async (item) => {
          if (item.title) {
            return item; // Title already exists, no need to fetch
          } else {
            const title = await fetchVideoTitle(extractVideoId(item.link), item.id);

            if(title !== item.title) {
              updateQueueItemTitle(item.id, title);
            }
            return { ...item, title };
          }
        })
      );
      setQueue(updatedQueue);

      if (!currentItem && updatedQueue.length > 0) {
        console.log("Setting initial current item...");
        setInitialCurrentItem(updatedQueue[0]);
      }
    }, 10000), // Debounce with 10 seconds delay
    [currentItem, queue] // Dependencies for debounce function
  );

  useEffect(() => {
    const unsubscribe = getQueue(debouncedCallback);

    return () => {
      unsubscribe();
      debouncedCallback.cancel(); // Clean up debounce when component unmounts
    };
  }, [getQueue, debouncedCallback]);   
    

  useEffect(() => {
    if (!currentItem && queue.length > 0) {
      setInitialCurrentItem(queue[0]);
    }
  }, [queue]);

  const setInitialCurrentItem = (item) => {
    setCurrentItem(item);
  };


  const fetchVideoTitle = async (videoId, itemId) => {
    try {
      const response = await fetch(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`);
      const data = await response.json();
  
      if (data.title) {
        const currentTitle = queue.find(item => item.id === itemId)?.title;
        if (currentTitle !== data.title) {
          console.log("Updating title...")
          await updateQueueItemTitle(itemId, data.title); // Only update if the title has changed
        }
        return data.title;
      } else {
        throw new Error("No title found");
      }
    } catch (error) {
      console.error("Failed to fetch video title:", error);
      return "Unknown Title";
    }
  };
  
  

  const handleVideoEnd = async () => {
    console.log("Video ended, dequeueing current item...");
    await proceedToNextItem();
  };

  const onPlayerReady = (event) => {
    playerRef.current = event.target;
    event.target.playVideo();

    // Check if player is not running
    if (event.target.getPlayerState() !== 1) {
      console.log("HERE!")
      event.target.playVideo();
    }
  };

  const onPlayerStateChange = (event) => {
    if (event.data === 0) { // Video ended
      handleVideoEnd();
    } else if (event.data === 1) { // Video playing
      console.log("Video is playing");
    } else if (event.data === 2) { // Video paused
      console.log("Video is paused, forcing play...");
      event.target.playVideo();
    }
  };

  const proceedToNextItem = async () => {
    if (currentItem) {
      await dequeue(currentItem.id); // Dequeue the current item
      setQueue(prevQueue => {
        const updatedQueue = prevQueue.filter(item => item.id !== currentItem.id);
        if (updatedQueue.length > 0) {
          setCurrentItem(updatedQueue[0]);
        } else {
          setCurrentItem(null);
        }
        return updatedQueue;
      });
    }
  };
  

  const handleNextSong = async () => {
    if (playerRef.current) {
      playerRef.current.stopVideo();
    }
    await proceedToNextItem();
  };

  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 1,
      controls: 0,
      disablekb: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      iv_load_policy: 3,
    },
  };

  return (
    <main className="w-full h-full flex">
      <section className="w-4/5 h-screen relative">
        {currentItem && currentItem.link ? (
          <YouTube
            key={currentItem.id} // Force re-render when currentItem changes
            videoId={extractVideoId(currentItem.link)}
            opts={opts}
            onReady={onPlayerReady}
            onStateChange={onPlayerStateChange} // Handle state change to force autoplay
            onEnd={handleVideoEnd} // Handle video end event
            style={{ height: "100vh"}}
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-zinc-500">No song playing...</p>
          </div>
        )}
      </section>

      <section className="w-1/5 h-screen p-4 space-y-4">
        <h2 className="text-xs text-zinc-500 uppercase bold">My Queue</h2>
        <button className="btn" onClick={handleNextSong}>Next song</button>
        <hr className="border-zinc-700" />
        <div className="flex-col gap-2">
          {queue.map((item, index) => (
            <QueueItem
              key={item.id}
              number={index + 1}
              title={item.title}
              isActive={currentItem && currentItem.id === item.id}
              onClick={() => setInitialCurrentItem(item)} // Allow selecting an item from the queue
            />
          ))}
        </div>
      </section>
    </main>
  );
}

export default App;
