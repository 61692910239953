export const extractVideoId = (url) => {
  const regex =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  const match = url.match(regex); // Fixed the issue here
  return match ? match[1] : null;
};


export const fetchVideoTitle = async (videoId, itemId) => {
    try {
      const response = await fetch(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`);
      const data = await response.json();
  
      if (data.title) {
        // const currentTitle = queue.find(item => item.id === itemId)?.title;
        // if (currentTitle !== data.title) {
        //   console.log("Updating title...")
        //   await updateQueueItemTitle(itemId, data.title); // Only update if the title has changed
        // }
        return data.title;
      } else {
        throw new Error("No title found");
      }
    } catch (error) {
      console.error("Failed to fetch video title:", error);
      return false;
    }
  };
  