import React, { useState } from 'react';
import logo from './youtube-logo.svg';
import useQueue from './hooks/useQueue';
import toast, { Toaster } from 'react-hot-toast';
import { extractVideoId, fetchVideoTitle } from './utils';

const Queue = () => {
    const { enqueue } = useQueue();

    const [link, setLink] = useState("");

    const handleInputChange = (event) => {
        setLink(event.target.value);
    };

    const handleReserve = async () => {
        if (link.trim()) {
            // Fetch video title to check if the link is valid
            const videoId = await fetchVideoTitle(extractVideoId(link));

            if(!videoId) {
                toast.error('Cannot reserve this song due to Youtube creator videos restrictions.');
                setLink("");
                return;
            }

            await enqueue(link);
            setLink(""); // Clear the input field after adding to the queue
            toast.success('Song reserved successfully!');
        } else {
            toast.error('Please enter a valid YouTube link');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-full w-full gap-6 pb-16 sm:pb-40 p-4">
            <Toaster />
            <section className="flex flex-col items-center justify-center w-full gap-6 sm:gap-8">
                <img src={logo} alt="YouTube logo" className='w-1/4 sm:w-1/12 h-auto' />
                <p className='text-xl sm:text-3xl text-zinc-200 font-bold text-center'>Your portable YouTube Karaoke Machine</p>
                <div className='flex flex-col sm:flex-row gap-2 w-full sm:w-2/3'>
                    <input
                        className='bg-zinc-900 text-zinc-300 p-3 sm:p-4 w-full placeholder:text-zinc-600 rounded focus:ring-zinc-600'
                        type="text"
                        placeholder="Paste YouTube link here to reserve"
                        value={link}
                        onChange={handleInputChange}
                    />
                    <button
                        className="text-md text-white border-none rounded transition bg-red-700 hover:bg-red-600 active:bg-red-800 p-3 sm:p-4 w-full sm:w-1/4"
                        onClick={handleReserve}
                    >
                        Reserve
                    </button>
                </div>
            </section>
        </div>
    );
}

export default Queue;
