// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCdDEVpm02fePrsKwwVhvt4yzrLvBScSmw",
  authDomain: "karaoke-e5120.firebaseapp.com",
  projectId: "karaoke-e5120",
  storageBucket: "karaoke-e5120.appspot.com",
  messagingSenderId: "826423358863",
  appId: "1:826423358863:web:253862d92dd9902613c7fa",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
