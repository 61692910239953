import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Queue from './Queue';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <body className='w-screen h-screen bg-zinc-800'>
      <Router>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/queue" element={<Queue />} />
        </Routes>
      </Router>
    </body>
);
