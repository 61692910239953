import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  onSnapshot,
  query,
  orderBy,
  limit,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../database/config";

const useQueue = () => {
  const enqueue = async (link) => {
    try {
      await addDoc(collection(db, "queue"), {
        link: link,
        timestamp: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const dequeue = async (id) => {
    try {
      await deleteDoc(doc(db, "queue", id));
    } catch (e) {
      console.error("Error dequeueing document: ", e);
      return null;
    }
  };

  const getQueue = (callback) => {
    const q = query(collection(db, "queue"), orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const queue = [];
      querySnapshot.forEach((doc) => {
        queue.push({
          id: doc.id, // Include the document ID
          ...doc.data(), // Spread the document data
        });
      });
      callback(queue);
    });

    // Return the unsubscribe function to stop listening when necessary
    return unsubscribe;
  };

  const updateQueueItemTitle = async (itemId, newTitle) => {
    const queueRef = doc(db, "queue", itemId); // Reference to the document in Firestore

    try {
      // Get the current document
      const docSnap = await getDoc(queueRef);

      if (docSnap.exists()) {
        const currentTitle = docSnap.data().title;

        // Update the title only if it has changed
        if (currentTitle !== newTitle) {
          await updateDoc(queueRef, { title: newTitle });
          console.log(`Title updated for item ${itemId} to: ${newTitle}`);
        } else {
          console.log(`Title for item ${itemId} is already up-to-date.`);
        }
      } else {
        console.error(`No document found with ID: ${itemId}`);
      }
    } catch (error) {
      console.error("Failed to update title in Firestore:", error);
    }
  };

  return { enqueue, getQueue, dequeue, updateQueueItemTitle };
};

export default useQueue;
